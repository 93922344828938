import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";

import axios from "axios";
import VueAxios from "vue-axios";
import globalMixin from "@mixins/Global.js";
import VueSmoothScroll from "vue3-smooth-scroll";
import { FontAwesomeIcon } from "@/plugins/font-awesome";

const app = createApp(App);

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.baseURL = "http://localhost:4646/api";
axios.defaults.baseURL = process.env.VUE_APP_API_ROOT; // Ne fonctionne pas

app.config.globalProperties.api_url = (endpoint) => {
  axios.defaults.baseURL + (endpoint == undefined ? "" : "/" + endpoint);
};

if (localStorage.getItem("token")) {
  axios.defaults.headers.common = {
    Authorization: localStorage.getItem("token"),
  };
}

app.config.productionTip = false;

app.mixin(globalMixin);
app.use(VueAxios, axios);
app.use(VueSmoothScroll, {
  offset: -150,
  updateHistory: false,
});

app.component("fa", FontAwesomeIcon);
app.use(store).use(router).mount("#app");
