export default {
  methods: {
    prop(obj, prop) {
      if (obj == null) return null;
      return obj.attributes[prop] ? obj.attributes[prop] : null;
    },
    dateFormat(date) {
      if (date == null) return null;
      return new Date(date).toLocaleDateString("FR-fr");
    },
    dateTimeFormat(date) {
      if (date == null) return null;
      return new Date(date).toLocaleString("FR-fr");
    },
    imagePlaceholder(str, opt) {
      str = str || null;
      opt = opt || {};
      var options = {
        width: opt.width || 350,
        height: opt.height || 500,
        fontSize: opt.fontSize || 56,
      };

      return (
        "https://placehold.jp/" +
        options.fontSize +
        "/ccc/fff/" +
        options.width +
        "x" +
        options.height +
        ".png" +
        (str != null ? "?text=" + str : "")
      );
    },
  },
};
