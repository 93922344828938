import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/LandingPage.vue"),
  },
  // {
  //   path: "/landing",
  //   name: "LandingPage",
  //   component: () => import("../views/LandingPage.vue")
  // },
  // {
  //   path: "/about",
  //   name: "About",
  //   component: () => import("../views/About.vue")
  // },
  // {
  //   path: "/contact",
  //   name: "Contact",
  //   component: () => import("../views/Contact.vue")
  // },
  // {
  //   path: "/cgu",
  //   name: "CGU",
  //   component: () => import("../views/CGU.vue")
  // },
  // {
  //   path: "/connect",
  //   name: "Connect",
  //   component: () => import("../views/Connect.vue")
  // },
  // {
  //   path: "/register",
  //   name: "Register",
  //   component: () => import("../views/Register.vue")
  // },
  // {
  //   path: "/books/:id",
  //   name: "Book",
  //   component: () => import("../views/BookDetails.vue"),
  //   props: (route) => ({ id: route.params.id })
  // },
  // {
  //   path: "/authors/:id",
  //   name: "Author",
  //   component: () => import("../views/AuthorDetails.vue"),
  //   props: (route) => ({ id: route.params.id })
  // },
  // {
  //   path: "/sagas/:id",
  //   name: "Saga",
  //   component: () => import("../views/SagaDetails.vue"),
  //   props: (route) => ({ id: route.params.id })
  // },
  // {
  //   path: "/collections/:id",
  //   name: "Collection",
  //   component: () => import("../views/CollectionDetails.vue"),
  //   props: (route) => ({ id: route.params.id })
  // },
  // {
  //   path: "/editions/:id",
  //   name: "Edition",
  //   component: () => import("../views/EditionDetails.vue"),
  //   props: (route) => ({ id: route.params.id })
  // },
  // {
  //   path: "/editors/:id",
  //   name: "Editor",
  //   component: () => import("../views/EditorDetails.vue"),
  //   props: (route) => ({ id: route.params.id })
  // },
  // {
  //   path: "/search",
  //   name: "Search",
  //   component: () => import("../views/Search.vue")
  // },
  // {
  //   path: "/users/:id",
  //   name: "User",
  //   component: () => import("../views/UserDetails.vue"),
  //   props: (route) => ({ id: route.params.id })
  // },
  // {
  //   path: "/library/:user_id",
  //   name: "Library",
  //   component: () => import("../views/Library.vue"),
  //   props: (route) => ({ user_id: route.params.user_id })
  // },
  // {
  //   path: "/my-library",
  //   name: "MyLibrary",
  //   component: () => import("../views/Library.vue"),
  //   props: { user_id: 'self' }
  // }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
