import { createStore } from "vuex";

export default createStore({
  state: {
    connected: false,
  },
  mutations: {
    CHANGE_CONNECTION_STATE: (state, connect) => (state.connected = connect),
  },
  actions: {
    connect: (store) => store.commit("CHANGE_CONNECTION_STATE", true),
    disconnect: (store) => store.commit("CHANGE_CONNECTION_STATE", false),
  },
  getters: {
    is_connected: (state) => state.connected,
  },
  modules: {},
});
